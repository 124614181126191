import  React from 'react';
import { Link } from 'gatsby';
import {Link as GoLink} from 'react-scroll/modules';
import Layout from '../../../components/layout';
import Seo from '../../../components/seo';
import ListBrand from '../../../components/list-brand';
import ListLink from '../../../components/list-link';
import Image from '../../../components/Image';


const MorsoPage = () => (
      <Layout page="brand-page morso">
        <Seo title="MORSO"/>
        <section className="page-head">
          <ListBrand/>
          <ListLink/>
          <h2 className="flex items-end md:ml-16">
            <Image filename="logo/morso.png" width={160} />
          </h2>
        </section>
        <section className="page-content flex-layout">
          <div className="main-content">
            <div id="about" className="content-block">
              <h3 className="sub-title"><span><i className="futura text-xl">morso</i>について</span></h3>
              <p>アンデルセンストーブのフラグシップブランドとして長年日本で愛されているデンマークのブランド。<br />
                デンマーク国内では唯一、王室御用達の称号を受けており創業170年以上の伝統と最新鋭の技術を兼ね備えたストーブは多くの愛好者が虜になっております。</p>
              <div className="mt-12">
                <Image className="full-image" filename="morso/about/main.jpg" />
              </div>
            </div>
            <div id="history" className="content-block">
              <div className="md:flex items-end">
                <div className="md:w-8/12 md:pr-4">
                  <h3 className="sub-title">The definition of hygge</h3>
                  <p className="text-3xl c-yellow leading-relaxed">伝統素材である鋳鉄に対しての<br className="pc" />深い拘りと信頼を真髄としてきた<br className="pc" />170年以上続く、もはや伝統工芸技術の賜物</p>
                  <p className="mt-6">1853年創業者のN.A.Christensen氏がMors島のLimfjordに鋳鉄工場を構えて以来、鋳鉄素材がもつユニークな特性に深い拘りと信頼を抱きつつ、開発とイノヴェーションという言葉を常に忘れることなく、時代と流行を追い求めながらも絶えず進化し続けて来ました。モルソーにとって“伝統“という言葉は”時代遅れ“ではなく、”経験から培った高度な伝統技術“という言葉を意味します。</p>
                </div>
                <div className="md:w-4/12 pl-4 md:mt-0 mt-6">
                  <Image filename="morso/about/logo-image.jpg" />
                </div>
              </div>
              <div className="mt-6 md:flex">
                <div className="md:w-1/2">
                  <Image className="full-image" filename="morso/about/history-01.jpg" />
                  <p className="mt-2">Limfjordの工場跡地</p>
                </div>
                <div className="md:w-1/2 md:mt-0 mt-6">
                  <Image className="full-image" filename="morso/about/history-02.jpg" />
                  <p className="mt-2">創業当時の工員</p>
                </div>
              </div>
            </div>
            <div id="designer" className="content-block">
              <p className="text-3xl c-yellow leading-relaxed">署名デンマーク人デザイナーが創り上げた<br className="pc" />飽きのこない容貌と機能性</p>
              <p className="mt-6">“デニッシュデザイン”はそのユニークな創造性と優れた機能性によって近年、世界各国より高い注目と評価を得ています。モルソー社では新モデルのデザイン発案にあたって、自国の芸術家たちに彼らの持つ才能を惜しみなく発揮させる機会を与えるという長い慣例を持っています。</p>
              <div className="mt-12 md:flex">
                <div className="md:w-1/2 md:pr-6">
                  <p className="c-gray text-2xl">初期を代表するストーブ</p>
                  <p className="mt-6">初期を代表するものとしては、デンマークのファニチャーアートをはじめとする業界に多大な影響を及ぼした、建築家のKaare Klint (1888～1954年)氏が1944年、その同じ年にノーベル文化賞を受賞したJohannes V. Jensen氏の詩をストーブの本体に刻印を施した、ユニークな“Klintストーブ”。そして、Georg Jensen’s Silversmith社の商品デザインにも長きに亘って携わり、数多くの工業製品を創り上げてきたSigvard Bernadotte氏が1960年代初頭にActon Bjorn氏とともに手掛けた、エナメル塗装仕上げのエレガントな鋳鉄製ストーブと美しい“ポットシリーズ”が挙げられます。</p>
                </div>
                <div className="md:w-1/2 md:pr-6 md:mt-0 mt-6">
                  <Image className="full-image" filename="morso/about/stove-01.jpg" />
                  <p className="mt-2">初期のモルソーストーブ</p>
                </div>
              </div>
              <div className="mt-12 md:flex">
                <div className="md:w-8/12 md:pr-6">
                  <p className="c-gray text-2xl">近代を代表するストーブ</p>
                  <p className="mt-6">近年を代表するものとしては、2008年にモルソーが初めて起用した女性デザイナーMonica Ritterband氏による薪ストーブ“7600シリーズ”と動物の脚をイメージした、ファイヤーツールセット、ケトル、トリベット、薪置きなどの“Feet accessory series（フィート アクセサリ シリーズ）”が挙げられます。</p>
                  <p className="mt-6">彼女が生み出した丸みを帯びたソフトで女性らしい表現力に満ち溢れた円柱状のフォルムは、武骨で男らしいというこれまでの薪ストーブのメージを一新した革新的なモデルとして注目を浴びています。</p>
                  <p className="mt-6">また、彼女がデザインしたモルソー7600シリーズは品質・機能性・使い勝手の良さ・革新性の観点から審査される世界最大級のコンペティションの製品デザイン部門において、世界約60ヶ国・数千点の応募の中から見事レッドドット・デザイン賞を2010年に受賞しています。</p>
                </div>
                <div className="md:w-4/12 md:pr-6 md:mt-0 mt-6">
                  <Image className="full-image" filename="morso/about/stove-02.jpg" />
                  <p className="mt-2">morso7644CB ※現在は取扱なし</p>
                  <Image className="mt-4" filename="morso/about/stove-02-reddot.jpg" />
                </div>
              </div>
              <div className="mt-12 md:flex">
                <div className="md:w-8/12 md:pr-6">
                  <p className="c-gray text-2xl">Sシリーズ</p>
                  <p className="mt-6">更に、2011年には住宅や商工業施設関連の“インテリア スタイリスト”として、今も幅広く活躍している“Andrea Larsson”女史によって創られた、エレガントさや軽快さを細かいディテールまで、余すところなく表現ができる、スチール鋼板素材を採用した ”Sシリーズ” を市場に送り出しました。</p>
                </div>
                <div className="md:w-4/12 md:pr-6 md:mt-0 mt-6">

                  <Image className="full-image" filename="morso/about/stove-03.jpg" />
                  <p className="mt-2">morso S50-40CB ※現在は取扱なし</p>
                </div>
              </div>
              <div className="mt-12 md:flex">
                <div className="md:w-8/12 md:pr-6">
                  <p className="c-gray text-2xl">6643CB</p>
                  <p className="mt-6">そして、2012年に“アウトドアーオーブン”や“グリルフォーノ”、“ファイヤーピット”、関連アクセサリなど、数々の屋外用クッキングシリーズ ”morso Living” のデザインを手掛けた他、数多くの受賞製品や特許デザインを創り上げるなど、優れた実績をもつ”Klaus Rath”氏が2014年には、スタイル性と機能性の両立をコンセプトにした、大きな曲面ガラスを装備した、観音開き扉の楕円型ストーブ“6643CB”を開発し、今日に至ってます。</p>
                </div>
                <div className="md:w-4/12 md:pr-6 md:mt-0 mt-6">
                  <Image className="full-image" filename="morso/about/stove-04.jpg" />
                  <p className="mt-2">morso6643CB</p>
                </div>
              </div>
              <div className="mt-12">
                <p>モルソーは彼等のプロダクトデザイナーの発案を通じて、彼ら自身だけではなく、デンマークという国の知名度アップにも貢献してきました。</p>
                <div className="md:flex mt-6">
                  <div className="flex md:w-3/5">
                    <div className="w-1/3">
                      <Image className="full-image" filename="morso/about/designer-01.jpg" />
                      <p className="mt-2 leading-normal">Kaare Klint</p>
                    </div>
                    <div className="w-1/3">
                      <Image className="full-image" filename="morso/about/designer-02.jpg" />
                      <p className="mt-2 leading-normal">Sigvard Bernadotte</p>
                    </div>
                    <div className="w-1/3">
                      <Image className="full-image" filename="morso/about/designer-03.jpg" />
                      <p className="mt-2 leading-normal">Monica Ritterband</p>
                    </div>
                  </div>
                  <div className="flex md:w-2/5 md:mt-0 mt-6 md:px-0 px-14">
                    <div className="w-1/2">
                      <Image className="full-image" filename="morso/about/designer-04.jpg" />
                      <p className="mt-2 leading-normal">Andrea Larsson</p>
                    </div>
                    <div className="w-1/2">
                      <Image className="full-image" filename="morso/about/designer-05.jpg" />
                      <p className="mt-2 leading-normal">Klaus Rath</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="title" className="content-block">
              <p className="text-3xl c-yellow leading-relaxed">デンマーク王室御用達の称号</p>
              <p className="mt-6">モルソーの薪ストーブはデンマーク王室にも認められています。モルソーのデンマーク王室への薪ストーブの納入は1915年にさかのぼって確認され、100年を超える納入実績を誇っており、デンマーク国内で薪ストーブと言えば、ほとんどの方が真っ先にモルソーの名前を上げるほど非常に有名です。その歴史は古く、1900年代初頭にAmalienborg 王室宮殿の一室に初めてインペリアル調のストーブを納めたのをきっかけに、クリスティエン王10世による皇帝陛下御用達の称号を授かり、その後1969年にデンマーク王室御用達の称号に格上げされ、モルソー製薪ストーブが持つその気高き暖かさは王室一族にまで認められています。</p>
              <p className="mt-6">モルソーのロゴマークに付いている王冠マークは、デンマーク王室御用達であることを公式に認められた特別なブランドにしか使う事を許されていません。それが許されているのは、薪ストーブのブランドでは唯一Morsoだけです。モルソーの王冠マークは、デンマーク王室も認める、高い格式と品質の証なのです。</p>
              <div className="mt-6 md:flex">
                <div className="md:w-1/2">
                  <Image className="full-image" filename="morso/about/title-01.jpg" />
                  <p className="mt-2">モルソーのロゴマーク</p>
                </div>
                <div className="md:w-1/2 md:mt-0 mt-6">
                  <Image className="full-image" filename="morso/about/title-02.jpg" />
                  <p className="mt-2">デンマーク王室御用達の証</p>
                </div>
              </div>
            </div>
            <div id="system" className="content-block left-end md:py-32 py-12 md:pr-20 pr-5">
              <p className="futura text-6xl text-white mb-6">CLEAN BURN SYSTEM</p>
              <div className="md:flex">
                <div className="md:w-8/12 md:pr-6">
                  <p className="text-3xl c-yellow leading-relaxed">環境への配慮と取り組み</p>
                  <p className="mt-6">二酸化炭素の排出やそれによる地球温暖化が叫ばれている最中、世界各国でも官民を挙げてありとあらゆる対策を講じていますが、モルソー社も例外でなく、薪燃焼で発生する煙に含まれる二酸化炭素やタール、微粒子などの不純物を再燃焼させる二次燃焼機能「クリーンバーン（CB）システム」を始め、環境に対する様々な取り組みを行っています。</p>
                </div>
                <div className="md:w-4/12 md:pl-6 md:mt-0 mt-6">
                  <Image className="full-image" filename="morso/about/system-01.jpg" />
                </div>
              </div>
              <p className="c-gray text-2xl mt-12">「クリーンバーン方式」燃焼システムについて</p>
              <div className="md:flex mt-6">
                <div className="md:w-8/12 md:pr-6">
                  <p>薪の燃焼によって発生する煙に含まれる「CO, タール、微粒子」などの不純物を再度燃焼させる、いわゆる二次燃焼をより高める機能をクリーンバーン（CB）といいます。<br />
                    モルソーストーブは、一般的に使用されている「キャタリティック・コンバスター」（触媒）を採用せず、ストーブそのものの構造を進化させる方法で環境に優しいクリーンな燃焼を実現しました。</p>
                  <p className="mt-6">それは、薪を燃やすための一次空気（右図①）・二次空気（右図②）に加え、煙突のドラフトを利用し、ストーブ背面より三次空気を取り入れ、不純物を更に燃焼させる燃焼方式です。三次空気（右図③）はバッフルプレートの奥に配置されたエアータンクノズルから、エアーシャワーのように自動的に燃焼室に供給され、CO、タール、微粒子などを三次燃焼させます。</p>
                  <p className="mt-6">この燃焼方式によって、より高度な燃焼が行われ、高い燃焼効率と共にクリーンな排気を実現しました（右図④）。また、薪の経済的消費と煙突内に付着するクレオソートやタールを減少させ、メンテナンスを軽減します。</p>
                </div>
                <div className="md:w-4/12 md:pl-6 md:mt-0 mt-6">
                  <Image className="full-image" filename="morso/about/system-02.jpg" />
                </div>
              </div>
              <p className="c-gray text-2xl mt-12">世界の厳しい排ガス基準をクリアーしているモルソーストーブ</p>
              <div className="md:flex flex-row-reverse mt-6">
                <div className="md:w-8/12 md:pl-6">
                  <p>モルソーの薪ストーブは世界中で最も厳しいとされている幾つかの基準に適合しています。微粒子排出量の最小限化に関する厳格な要件を主として規定しているノルウェー基準NS3058/3059にも適合しています。</p>
                  <p className="mt-6">我々の環境保護への取り組みを示す為にも、モルソーストーブの殆どのモデルは“Nordic Swan Eco-Label(ノルディックスワンエコラベル)“の認定も受けています。</p>
                  <p className="mt-6">これは使用時におけるストーブの自然環境に対する影響を軽減させるだけではなく、実際の製造過程の間や梱包材料等の選択によって引き起こされる環境への負荷に対しても注視しています。</p>
                </div>
                <div className="md:w-4/12 md:pr-6 md:mt-0 mt-6">
                  <Image className="full-image" filename="morso/about/system-03.jpg" />
                </div>
              </div>
              <div className="md:flex flex-row-reverse mt-6">
                <div className="md:w-8/12 md:pl-6">
                  <p>また、イギリス環境・食糧・農村地域省（DEFRA）によって認定されたモデルに与えられる「defraマーク」も、幾つかのモデルで認定を受けています。その他にモルソーストーブはアメリカをはじめ、イギリス、オーストラリアにて行われている低微粒子の排出量テストも受けており、ほとんどのモデルがヨーロッパ基準EN13240に適合し、CEマークを取得しています。</p>
                </div>
                <div className="md:w-4/12 md:pr-6 md:mt-0 mt-6">
                  <Image className="full-image" filename="morso/about/system-04.jpg" />
                </div>
              </div>
              <p className="c-gray text-2xl mt-12">モルソー社が行っている、環境に対してのその他の配慮と取り組み</p>
              <div className="md:flex mt-6">
                <div className="md:w-9/12 md:pr-6">
                  <p className="c-green text-2xl">再利用された鉄屑を使用</p>
                  <p className="mt-2">モルソーストーブを構成する鋳鉄部品のうち99％が再精錬されたスクラップで占めています。購入したストーブが、かつて自動車や街路灯などに使用されたものであったものが、今の姿として再生されていることを想像するだけでも、驚きを感じるかと思います。</p>
                </div>
                <div className="md:w-3/12 md:pl-6 md:mt-0 mt-6">
                  <Image className="full-image" filename="morso/about/system-05.jpg" />
                  <p className="mt-2">回収された鉄屑</p>
                </div>
              </div>
              <div className="md:flex mt-6">
                <div className="md:w-9/12 md:pr-6">
                  <p className="c-green text-2xl">サスティナブル（持続可能）クリーンエネルギーを採用</p>
                  <p className="mt-2">製造過程において消費されている莫大なエネルギーのうちの約80％は風力発電等を主とした持続可能なクリーンエネルギーによって賄われています。<br />
                    ＊2009年5月時点</p>
                </div>
                <div className="md:w-3/12 md:pl-6 md:mt-0 mt-6">
                  <Image className="full-image" filename="morso/about/system-06.jpg" />
                  <p className="mt-2">コペンハーゲン市内の風車</p>
                </div>
              </div>
              <div className="md:flex mt-6">
                <div className="md:w-9/12 md:pr-6">
                  <p className="c-green text-2xl">再利用された梱包材料</p>
                  <p className="mt-2">モルソーストーブの梱包材料は再生紙を利用したものであり、梱包材料に印刷する水性インクの色数を１色にするとともに必要最低限のインクの量に止めています。</p>
                </div>
                <div className="md:w-3/12 md:pl-6 md:mt-0 mt-6">
                  <Image className="full-image" filename="morso/about/system-07.jpg" />
                  <p className="mt-2">梱包済のストーブ本体</p>
                </div>
              </div>
              <div className="mt-6">
                <p className="c-green text-2xl">廃棄分類作業</p>
                <p className="mt-2">使用されずに眠っている状態の廃材及び、製造過程上で発生した廃材の殆どは、環境管理に関する国際標準化機構ISO14001を取得した選別センターに送られ、選別・再利用を行っています。</p>
              </div>
            </div>
            <div id="lineup" className="content-block">
              <div className="md:flex">
                <div className="md:w-6/12 md:pr-4">
                  <p className="text-3xl c-yellow leading-relaxed">季節や場所を問わず<br className="pc" />炎と触れ合うことのできる<br />多彩な商品ラインアップ</p>
                  <p className="mt-6">暖房用機器だけではなく、春から秋にかけてのアウトドアシーズンに活躍する”MORSO Living” のアウトドアーオーブンをはじめ、関連アクセサリグッズなど季節に関係なく、炎と接することのできる充実した商品をお届けします。</p>
                </div>
                <div className="md:w-6/12 md:pl-4 md:mt-0 mt-6">
                  <Image filename="morso/about/logo-image-02.jpg" />
                  <Link to="/accessory/" className="text-link white py-0 mt-12">
                    アクセサリはこちら
                  </Link>
                </div>
              </div>
            </div>
            <div className="content-block">
              <div className="md:flex">
                <div className="md:w-1/2">
                  <Image className="full-image" filename="morso/about/lineup-01.jpg" />
                  <p className="mt-2">アウトドアオーブン</p>
                </div>
                <div className="md:w-1/2 md:mt-0 mt-6">
                  <Image className="full-image" filename="morso/about/lineup-02.jpg" />
                  <p className="mt-2">グリルフォーノ</p>
                </div>
              </div>
            </div>
            <div id="pdf" className="content-block">
              <Link className="btn full white" to="/brand/morso/lineup/">
                MORSO 商品一覧
              </Link>
              <a className="btn pdf full mt-5" href="/update/pdf/andersen.pdf" target="_blank">
                PDFカタログ ダウンロード
              </a>
            </div>
          </div>
          <div className="side-list">
        <ul className="sticky">
              <li>
                <GoLink to="about" spy smooth duration={800}>
                  morsoについて
                </GoLink>
              </li>
              <li>
                <GoLink to="history" spy smooth duration={800}>
                  160年以上の伝統工芸技術
                </GoLink>
              </li>
              <li>
                <GoLink to="designer" spy smooth duration={800}>
                  飽きの来ないデザインと機能性
                </GoLink>
              </li>
              <li>
                <GoLink to="title" spy smooth duration={800}>
                  デンマーク王室御用達の称号
                </GoLink>
              </li>
              <li>
                <GoLink to="system" spy smooth duration={800}>
                  CLEAN BURN SYSTEM
                </GoLink>
              </li>
              <li>
                <GoLink to="lineup" spy smooth duration={800}>
                  多彩な商品ラインアップ
                </GoLink>
              </li>
              <li>
                <GoLink className="c-attention" to="pdf" spy smooth duration={800}>
                  PDFカタログ
                </GoLink>
              </li>
            </ul>
          </div>
        </section>
        <section className="bread">
          <ul>
            <li>
              <Link to="/">
                <Image filename="ico/home.png" />
              </Link>
            </li>
            <li>
              <Link to="/brand/">ブランド</Link>
            </li>
            <li>
              <Link to="/brand/morso/">MORSO</Link>
            </li>
            <li>morsoについて</li>
          </ul>
        </section>
      </Layout>
  );

export default MorsoPage;
